import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Spinner, Table, Modal, Button } from "react-bootstrap";
import axios from "axios";
import "./Directory.css";

function Directory() {
  const [isLoading, setLoading] = useState(true);
  const [memberData, setMemberData] = useState([]);
  const [search, setSearch] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedMember, setSelectedMember] = useState();

  const handleSearch = (event) => {
    setSearch(event.target.value);
  };

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = (member) => {
    setSelectedMember(member);
    setShowModal(true);
  };

  useEffect(() => {
    axios
      .get("/users/getAllMembers")
      .then((res) => {
        const sortedData = res.data.sort((a, b) =>
          a.lname.localeCompare(b.lname),
        );
        setMemberData(sortedData);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const filteredMembers = memberData.filter(
    (item) =>
      item.fname.toLowerCase().includes(search.toLowerCase()) ||
      item.lname.toLowerCase().includes(search.toLowerCase()) ||
      (item.committee || "").toLowerCase().includes(search.toLowerCase()) ||
      (item.major || "").toLowerCase().includes(search.toLowerCase()),
  );

  return (
    <div className="MyPoints">
      <Container>
        {isLoading ? (
          <div className="d-flex justify-content-center">
            <Spinner animation="border" />
          </div>
        ) : (
          <>
            <h2 className="display-4 text-center"> Member Directory </h2>
            <label htmlFor="search">
              Search: <input id="search" type="text" onChange={handleSearch} />
            </label>
            <p className="h6 small">
              Click on any row to view full information
            </p>
            <div className="table-responsive">
              <Table striped bordered hover className="text-center mb-5">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Committee</th>
                    <th className="major-column">Major</th>
                    <th>Position</th>
                    <th className="major-column">More Info</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredMembers.map((member, key) => (
                    <tr key={key} onClick={() => handleShowModal(member)}>
                      <td>{`${member.fname} ${member.lname}`}</td>
                      <td>{member.committee || "N/A"}</td>
                      <td className="major-column">{member.major || "N/A"}</td>
                      <td>
                        {`${member.position || "N/A"} - ${
                          member.committee || "N/A"
                        }` || "N/A"}
                      </td>
                      <td className="major-column">
                        <button type="button" className="btn btn-primary">
                          {" "}
                          More Info
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>

            <Modal show={showModal} onHide={handleCloseModal}>
              <Modal.Header closeButton>
                <strong>
                  {selectedMember
                    ? `${selectedMember.fname} ${selectedMember.lname}`
                    : "Member Details"}{" "}
                </strong>
              </Modal.Header>
              <Modal.Body>
                {selectedMember && (
                  <>
                    <p>
                      <strong>Name:</strong>{" "}
                      {`${selectedMember.fname} ${selectedMember.lname}`}
                    </p>
                    <p>
                      <strong>Email:</strong> {selectedMember.email}
                    </p>
                    <p>
                      <strong>Phone:</strong>{" "}
                      {selectedMember.phoneNumber || "N/A"}
                    </p>
                    <p>
                      <strong>Committee:</strong>{" "}
                      {selectedMember.committee || "N/A"}
                    </p>
                    <p>
                      <strong>Major:</strong> {selectedMember.major || "N/A"}
                    </p>
                    <p>
                      <strong>Instagram:</strong>{" "}
                      {selectedMember.socialMedias?.Instagram ? (
                        <a
                          href={`http://instagram.com/${selectedMember.socialMedias.Instagram}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {selectedMember.socialMedias.Instagram}
                        </a>
                      ) : (
                        "N/A"
                      )}
                    </p>
                    <p>
                      <strong>LinkedIn:</strong>{" "}
                      {selectedMember.socialMedias?.LinkedIn ? (
                        <a
                          href={`http://linkedin.com/in/${selectedMember.socialMedias.LinkedIn}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          LinkedIn Profile
                        </a>
                      ) : (
                        "N/A"
                      )}
                    </p>
                  </>
                )}
              </Modal.Body>
              <Modal.Footer>
                <Button variant="danger" onClick={handleCloseModal}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        )}
      </Container>
    </div>
  );
}

Directory.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Directory);
