import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import { Container, Navbar, Nav } from "react-bootstrap";
import axios from "axios";
import "./Navbar.css";

function NavBar(props) {
  // State Management
  const [loggedIn, setLoggedIn] = useState(false);
  const [admin, setAdminNav] = useState(false);
  const [manager, setManagerNav] = useState(false);
  const [navBarColor, setNavBarColor] = useState("#adb5bd"); // Default color

  const isNm = props.auth.user.role === "nm";

  useEffect(() => {
    if (props.auth.isAuthenticated) {
      setLoggedIn(true);

      axios
        .get(`/users/getUserById`, { params: { userID: props.auth.user.id } })
        .then((res) => {
          setNavBarColor(res.data.fun?.navBarColor || "#adb5bd");
        })
        .catch((err) => console.log(err));
    } else {
      setLoggedIn(false);
      setManagerNav(false);
      setAdminNav(false);
    }
    if (props.auth.user.role === "ec" || props.auth.user.role === "admin") {
      setManagerNav(true);
    }
    if (props.auth.user.role === "admin") {
      setAdminNav(true);
    }
  }, [props.auth, props.auth.isAuthenticated]);

  const onLogoutClick = (e) => {
    e.preventDefault();
    props.logoutUser();
    setLoggedIn(false);
  };

  return (
    <div className="Navbar shadow-sm" style={{ backgroundColor: navBarColor }}>
      <Navbar collapseOnSelect expand="lg">
        <Container fluid>
          <Navbar.Brand href="/">
            <img
              alt="SEC"
              src="https://sec.tamu.edu/images/secbasic.png"
              width="auto"
              height="30"
              className="d-inline-block align-top mr-2"
            />
            Membership Hub
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              {loggedIn && !isNm && (
                <Nav.Link href="/dashboard">Dashboard</Nav.Link>
              )}
              {loggedIn && !isNm && <Nav.Link href="/events">Events</Nav.Link>}
              {loggedIn && !isNm && (
                <Nav.Link href="/meetings">Meetings</Nav.Link>
              )}
              {loggedIn && !isNm && (
                <Nav.Link href="/directory">Members</Nav.Link>
              )}
              {loggedIn && (
                <Nav.Link href="/applications">Applications</Nav.Link>
              )}
              {loggedIn && !isNm && (
                <Nav.Link href="/profile">Profile</Nav.Link>
              )}
            </Nav>
            <Nav>
              {admin && <Nav.Link href="/admin">Admin</Nav.Link>}
              {manager && <Nav.Link href="/manager">Events Creation</Nav.Link>}
              {loggedIn && <Nav.Link onClick={onLogoutClick}>Logout</Nav.Link>}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

NavBar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(NavBar);
