import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useState, useEffect } from "react";
import axios from "axios";
import { committees, positions } from "../layout/dropDown";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { SketchPicker } from "react-color";

function Profile(props) {
  const [userData, setUserData] = useState({
    phoneNumber: "",
    major: "",
    committee: "",
    class: "",
    position: "",
    socialMedias: {
      Instagram: "",
      LinkedIn: "",
    },
    navBarColor: "#adb5bd",
  });

  useEffect(() => {
    const userID = props.auth.user.id;
    axios
      .get(`/users/getUserById`, { params: { userID: userID } })
      .then((res) => {
        setUserData({
          phoneNumber: res.data.phoneNumber,
          major: res.data.major,
          committee: res.data.committee,
          class: res.data.class,
          position: res.data.position,
          socialMedias: {
            Instagram: res.data.socialMedias?.Instagram || "",
            LinkedIn: res.data.socialMedias?.LinkedIn || "",
          },
          navBarColor: res.data.fun?.navBarColor || "#adb5bd",
        });
      })
      .catch((err) => console.log(err));
  }, [props.auth.user.id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "instagram") {
      setUserData((prevData) => ({
        ...prevData,
        socialMedias: {
          ...prevData.socialMedias,
          Instagram: value,
        },
      }));
    } else if (name === "linkedin") {
      setUserData((prevData) => ({
        ...prevData,
        socialMedias: {
          ...prevData.socialMedias,
          LinkedIn: value,
        },
      }));
    } else {
      setUserData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const userID = props.auth.user.id;
    axios
      .patch("/users/updateUserProfile", {
        data: {
          userID,
          ...userData,
        },
      })
      .catch((err) => console.log(err));
    alert("Profile Updated!");
  };

  return (
    <Container
      className="d-flex justify-content-center align-items-center"
      style={{ minHeight: "100vh" }}
    >
      <Row className="justify-content-md-center">
        <Col md={20}>
          <h2 className="text-center my-4">Update Profile</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formPhoneNumber" className="mb-3">
              <Form.Label>Phone Number:</Form.Label>
              <Form.Control
                type="text"
                name="phoneNumber"
                placeholder="123-456-7890"
                value={userData.phoneNumber}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formMajor" className="mb-3">
              <Form.Label>Major:</Form.Label>
              <Form.Control
                type="text"
                name="major"
                value={userData.major}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formCommittee" className="mb-3">
              <Form.Label>Committee:</Form.Label>
              <Form.Select
                name="committee"
                value={userData.committee}
                onChange={handleChange}
              >
                <option value="">Select Committee</option>
                {committees.map((committee, index) => (
                  <option key={index} value={committee}>
                    {committee}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="formClass" className="mb-3">
              <Form.Label>Class:</Form.Label>
              <Form.Control
                type="text"
                name="class"
                placeholder="Spring 2024"
                value={userData.class}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formPosition" className="mb-3">
              <Form.Label>Position:</Form.Label>
              <Form.Select
                name="position"
                value={userData.position}
                onChange={handleChange}
              >
                <option value="">Select Position</option>
                {positions.map((position, index) => (
                  <option key={index} value={position}>
                    {position}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group controlId="formInstagram" className="mb-3">
              <Form.Label>Instagram (username):</Form.Label>
              <Form.Control
                type="text"
                name="instagram"
                placeholder="john_smith"
                value={userData.socialMedias.Instagram}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formLinkedIn" className="mb-3">
              <Form.Label>LinkedIn (username):</Form.Label>
              <Form.Control
                type="text"
                name="linkedin"
                placeholder="johnsmith"
                value={userData.socialMedias.LinkedIn}
                onChange={handleChange}
              />
            </Form.Group>

            <Form.Group controlId="formNavBarColor" className="mb-3">
              <Form.Label>NavBar Color:</Form.Label>
              <div className="d-flex justify-content-center mt-2">
                <SketchPicker
                  color={userData.navBarColor}
                  onChangeComplete={(color) =>
                    setUserData((prevData) => ({
                      ...prevData,
                      navBarColor: color.hex,
                    }))
                  }
                />
              </div>
            </Form.Group>

            <Button variant="primary" type="submit" className="w-100 mt-3">
              Update Profile
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

Profile.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Profile);
