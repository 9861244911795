import React, { useState, useEffect } from "react";
import { Container, Button, Nav } from "react-bootstrap";
import { useSpring, animated } from "react-spring";
import PropTypes from "prop-types";
import { connect } from "react-redux";

function Landing(props) {
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    if (props.auth.isAuthenticated) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [props.auth, props.auth.isAuthenticated]);

  const springProps = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    delay: 100,
  });

  const userRole = props.auth.user ? props.auth.user.role : "nm";

  return (
    <div className="Landing">
      <Container>
        <animated.div style={springProps}>
          <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{ height: "83vh" }}
          >
            <div>
              <h1 className="display-1 text-center">
                <b>Student Engineers' Council</b>
              </h1>
            </div>
            <div>
              {!loggedIn ? (
                <React.Fragment>
                  <Nav.Link href="/login" className="text-center">
                    <Button className="rounded-3" size="lg">
                      Login or Register!
                    </Button>
                  </Nav.Link>
                  <p className="text-center">
                    <i>If you wish to apply, create an account first!</i>
                  </p>
                </React.Fragment>
              ) : (
                <div className="d-flex flex-row align-items-center justify-content-center">
                  <Nav.Link href="/dashboard">
                    <Button className="rounded-3" size="lg">
                      Dashboard
                    </Button>
                  </Nav.Link>
                  {userRole !== "nm" && (
                    <Nav.Link
                      href="https://alumni.secsystems.net/login"
                      target="_blank"
                    >
                      <Button className="btn-success rounded-3" size="lg">
                        Alumni Hub
                      </Button>
                    </Nav.Link>
                  )}
                </div>
              )}
            </div>
          </div>
          <p className="text-center muted">
            Powered by <i>SEC Systems Administration</i>
          </p>
          <p className="text-center">
            © 2025 Student Engineers' Council. All Rights Reserved.
          </p>
        </animated.div>
      </Container>
    </div>
  );
}

Landing.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Landing);
