// List of committees
export const committees = [
  "Executive Board",
  "Career Fair",
  "Corporate Relations",
  "Engineering Development",
  "Finance",
  "ProTrip",
  "Engineer's Week",
  "EnVision",
  "Philanthropy",
  "Special Events",
  "Legislation",
  "Society and Graduate Relations",
  "Student Relations",
  "Internal Relations",
  "Marketing",
  "Membership",
  "Systems Administration",
];

export const positions = [
  "President",
  "Vice President - Internal",
  "Vice President - External",
  "Vice President - Operations",
  "Vice President - Development",
  "Executive Secretary",
  "Executive Council",
  "General Member",
];
